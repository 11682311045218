/**
 * Created by olga on 23.10.2024
 */

var Meta = function () {
    cleverapps.EventEmitter.call(this);

    this.logic = this.createLogic();
    this.adapter = this.createAdapter();
};

Meta.prototype = Object.create(cleverapps.EventEmitter.prototype);
Meta.prototype.constructor = Meta;

Meta.prototype.createAdapter = function () {
    var types = { // tmp
        garden: "simple",
        klondike: "simple",
        tilehero: "simple",
        adventure: "simple",
        differences: "simple",
        magicwords: "simple",
        runes: "simple",
        scramble: "simple",
        spades: "simple",
        tripeaks: "simple",
        woodenblock: "simple",
        zenmatch: "simple",

        crocword: "hose",
        riddles: "hose",

        epicart: "shortmeta",
        olympics: "shortmeta",

        hearts: "merge",
        fairy: "merge",
        hustlemerge: "merge",
        mergecraft: "merge",
        wondermerge: "merge",

        heroes: "farm",

        wordsoup: "homefix"
    };

    switch (types[cleverapps.config.name]) {
        case "hose":
            return new HoseAdapter();
        case "shortmeta":
            return new ShortMetaAdapter();
        case "simple":
            return new SimpleAdapter();
        case "homefix":
            return new HomefixAdapter();
        case "farm":
            return new FarmAdapter();
        case "merge":
            return new MergeMetaAdapter();
    }

    return new DefaultMetaAdapter();
};

Meta.prototype.createLogic = function () {
    var types = { // tmp
        garden: "simple",
        klondike: "simple",
        tilehero: "simple",
        adventure: "simple",
        differences: "simple",
        magicwords: "simple",
        runes: "simple",
        scramble: "simple",
        spades: "simple",
        tripeaks: "simple",
        woodenblock: "simple",
        zenmatch: "simple",

        crocword: "hose",
        riddles: "hose",

        epicart: "shortmeta",
        olympics: "shortmeta",

        hearts: "merge",
        fairy: "merge",
        hustlemerge: "merge",
        mergecraft: "merge",
        wondermerge: "merge",

        heroes: "farm",

        wordsoup: "homefix"
    };

    switch (types[cleverapps.config.name]) {
        case "shortmeta":
        case "hose":
        case "simple":
            return new SimpleLogic();
        case "farm":
            return new FarmLogic();
        case "homefix":
            return new HomefixLogic();
        case "merge":
            return new MergeLogic();
    }

    return new NoMetaLogic();
};

Meta.prototype.load = function () {
    var needSave = this.adapter.load();

    this.switchLocation(this.logic.getMainLocation());

    if (needSave) {
        this.save();
    }
};

Meta.prototype.save = function (fromSever) {
    this.adapter.save(fromSever);
};

Meta.prototype.updateInfo = function (serverData) {
    this.adapter.updateInfo(serverData);

    this.save(true);

    if (cleverapps.highscore) {
        cleverapps.highscore.updateInfo(serverData);
        cleverapps.highscore.save(true);
    }
};

Meta.prototype.getInfo = function () {
    var data = this.adapter.getInfo();

    if (cleverapps.highscore) {
        Object.assign(data, cleverapps.highscore.getInfo());
    }

    return data;
};

Meta.prototype.refreshLocation = function () {
    this.switchLocation(this.logic.getMainLocation());
};

Meta.prototype.loadLocation = function (locationId) {
    return this.adapter.loadLocation(locationId);
};

Meta.prototype.saveLocation = function (locationId, data) {
    this.adapter.saveLocation(locationId, data);
};

Meta.prototype.switchLocation = function (locationId) {
    if (typeof Merge !== "undefined" && Merge.currentMerge) {
        Merge.currentMerge.stop();
        Merge.currentMerge = undefined;
    }

    if (typeof Homefix !== "undefined" && Homefix.currentHomefix) {
        Homefix.currentHomefix.stop();
        Homefix.currentHomefix = undefined;
    }

    var LocationClass = this.logic.getLocationClass();
    this.location = new LocationClass(locationId);

    if (typeof LivesHelper !== "undefined") {
        LivesHelper.Switch(this.location.slot);
    }

    cleverapps.playSession.set(cleverapps.EVENTS.META_LOCATION, this.location.locationId);
};

Meta.prototype.gotoLocationScene = function (f) {
    var MainSceneClass = this.getMainScene();
    cleverapps.scenes.replaceScene(new MainSceneClass(), f);
};

Meta.prototype.gotoMainLocation = function (f) {
    if (cleverapps.config.demoMode) {
        cleverapps.unitsDemoMode.gotoUnitsDemoScene(f);
        return;
    }

    var locationId = this.logic.getMainLocation();
    
    if (this.selectedLocationId() !== locationId) {
        this.switchLocation(locationId);
    }

    this.gotoLocationScene(f);
};

Meta.prototype.isLocationAvailable = function (locationId) {
    return this.logic.isLocationAvailable(locationId);
};

Meta.prototype.listAvailableLocations = function () {
    if (this.logic.listAvailableLocations) {
        return this.logic.listAvailableLocations();
    }

    var locationId = 0;
    var locations = [];

    while (this.isLocationAvailable(locationId)) {
        locations.push(locationId);
        locationId++;
    }

    return locations;
};

Meta.prototype.listActiveLocations = function () {
    return this.logic.listActiveLocations();
};

Meta.prototype.getSelectedLocation = function () {
    return this.location;
};

Meta.prototype.selectedLocationId = function () {
    return this.location.locationId;
};

Meta.prototype.nextLocationId = function (locationId) {
    locationId = this.logic.getNextLocation(locationId);

    if (this.isLocationAvailable(locationId)) {
        return locationId;
    }
};

Meta.prototype.gamePlayed = function (game) {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.METHA_LEVEL);
    cleverapps.playSession.set(cleverapps.EVENTS.METHA_LEVEL, true);

    this.getSelectedLocation().gamePlayed(game);
};

Meta.prototype.isCompleted = function () {
    return this.logic.isCompleted();
};

Meta.prototype.moveNextLocation = function () {
    this.logic.moveNextLocation();

    this.switchLocation(this.logic.getMainLocation());
    this.save();
};

Meta.prototype.startLocation = function (locationId) {
    this.logic.startLocation(locationId);
    this.save();
};

Meta.prototype.finishLocation = function (locationId) {
    this.logic.finishLocation(locationId);
    this.save();
};

Meta.prototype.overrideLocationInfo = function (locationId, data) {
    this.logic.overrideLocationInfo(locationId, data);
    this.save();
};

Meta.prototype.removeSilents = function () {
    this.logic.listActiveLocations().forEach(function (locationId) {
        if (this.logic.isLocationCompleted(locationId) && this.logic.canRemoveSilently(locationId)) {
            this.finishLocation(locationId);
        }
    }.bind(this));
};

Meta.prototype.wantsToPlay = function (f, level) {
    if (cleverapps.lives && cleverapps.lives.isEmpty()) {
        if (cleverapps.unlimitedLives && cleverapps.unlimitedLives.freeOfferIsAvailable()) {
            cleverapps.unlimitedLives.showFreeOffer(f);
        } else {
            new LivesShopWindow();
            cleverapps.focusManager.onceNoWindowsListener = f;
        }

        return;
    }

    var location = this.getSelectedLocation();
    if (location.getWantsToPlayFarmLevel) {
        level = location.getWantsToPlayFarmLevel(level);
    } else {
        level = level || cleverapps.meta.getCurrentLevel();
    }

    if (cleverapps.knockoutGame) {
        cleverapps.knockoutGame.wantsToPlay(f, level);
    } else {
        cleverapps.meta.playLevel(f, level);
    }
};

Meta.prototype.wantsToReplay = function (f, level) {
    cleverapps.meta.playLevel(f, level);
};

Meta.prototype.reset = function () {
    this.location = undefined;

    this.logic.reset();
    this.adapter.reset();

    this.switchLocation(this.logic.getMainLocation());
};

Meta.prototype.getMainScene = function () {
    if (connector.info.source === "playable") {
        return PlayableAdsScene;
    }

    var LocationClass = this.logic.getLocationClass();
    return LocationClass.GetMainScene();
};

Meta.prototype.processChatMessage = function (message) {
    this.logic.processChatMessage(message);
};

Meta.prototype.playLevel = function (f, level) {
    if (cleverapps.isLevels() && level.episodeNo >= levels.user.getEpisodesAmount()) {
        f();
        return;
    }

    if (!LevelStartWindow.isAvailable(level) || cleverapps.config.features.includes("boosters_before") && !WindowBoostersBefore.isAvailable()) {
        level.play(f);
        return;
    }

    cleverapps.focusManager.showControlsWhileFocused("MenuBarGoldItem");

    if (level.building) {
        new BuildingStartWindow({
            building: level.building,
            level: level
        });
    } else {
        new LevelStartWindow(level.getStartWindowOptions());
    }
    cleverapps.focusManager.onceNoWindowsListener = f;
};

Meta.prototype.getCurrentLevel = function (building) {
    var location = this.getSelectedLocation();
    if (location.getCurrentFarmLevel) {
        return location.getCurrentFarmLevel(building);
    }

    if (cleverapps.isKnockoutGame()) {
        return cleverapps.knockoutGame.getNextLevel();
    }

    return cleverapps.user.getCurrentLevel();
};

Meta.SLOT_MAIN = "";
Meta.SLOTS = [Meta.SLOT_MAIN];

if (cleverapps.config.type === "merge" || cleverapps.config.name === "garden") {
    Meta.EXPEDITION_SLOT1 = "1";
    Meta.EXPEDITION_SLOT2 = "2";
    Meta.EXPEDITION_SLOT3 = "3";
    Meta.EXPEDITION_SLOT4 = "4";

    Meta.SLOTS = [Meta.SLOT_MAIN, Meta.EXPEDITION_SLOT1, Meta.EXPEDITION_SLOT2, Meta.EXPEDITION_SLOT3, Meta.EXPEDITION_SLOT4];
}
