/**
 * Created by mac on 1/27/21
 */

var NoMetaLocation = function (locationId) {
    BaseLocation.call(this, "nometa", locationId);

    this.slot = Meta.SLOT_MAIN;
};

NoMetaLocation.prototype = Object.create(BaseLocation.prototype);
NoMetaLocation.prototype.constructor = NoMetaLocation;

NoMetaLocation.prototype.load = function () {};

NoMetaLocation.prototype.getInfo = function () {};

NoMetaLocation.prototype.withWands = function () {};

NoMetaLocation.GetMainScene = function () {
    return GameScene;
};
