/**
 * Created by Denis Kuzin on 12 february 2025
 */

var ScrollableIconTable = function (options) {
    cleverapps.EventEmitter.call(this);

    this.minIconsCount = options.minIconsCount;
    this.addToStart = options.addToStart;

    this.active = options.active;
    this.active = this.createFakeIcons().concat(this.active);
    this.active.reverse();

    this.pending = [];
};

ScrollableIconTable.prototype = Object.create(cleverapps.EventEmitter.prototype);
ScrollableIconTable.prototype.constructor = ScrollableIconTable;

ScrollableIconTable.prototype.createFakeIcons = function () {
    var icons = [];
    for (var i = 0; i < this.minIconsCount - this.active.length; i++) {
        icons.push(undefined);
    }
    return icons;
};

ScrollableIconTable.prototype.removeIcon = function (icon) {
    var toRemoveIndex = this.active.indexOf(icon);

    this.active.forEach(function (icon, i) {
        if (i === toRemoveIndex) {
            return;
        }

        this.trigger("move", i, i < toRemoveIndex ? "down" : "up");
    }.bind(this));
    this.active.splice(toRemoveIndex, 1);

    var fakeIcons = this.createFakeIcons();
    this.active = this.active.concat(fakeIcons);
    this.removeInProgress = true;

    new ActionPlayer([
        function (f) {
            this.trigger("remove", toRemoveIndex, false, f);
        }.bind(this),

        function (f) {
            fakeIcons.forEach(function () {
                this.trigger("add", { fake: true });
            }.bind(this));

            f();
        }.bind(this),

        function (f) {
            this.trigger("resize", f);
        }.bind(this),

        function (f) {
            var iconIndex = toRemoveIndex;
            if (!this.active[toRemoveIndex]) {
                iconIndex--;
            }

            this.trigger("scrollTo", iconIndex, true);
            f();
        }.bind(this),

        function (f) {
            Merge.currentMerge.counter.setTimeout(f, 500);
        },

        function (f) {
            delete this.removeInProgress;
            this.pending.forEach(function (p) {
                this.addIcon(p);
            }.bind(this));
            this.pending = [];
            f();
        }.bind(this)
    ]).play();
};

ScrollableIconTable.prototype.addIcon = function (newIcon, options) {
    if (this.removeInProgress) {
        this.pending.push(newIcon);
        return;
    }

    options = options || {};
    var callback = options.callback || function () {};

    if (!options.silent) {
        cleverapps.audio.playSound(bundles.questsgroupicon.urls.quest_new_close_effect);
    }

    var currentIcon = this.active.find(function (icon) {
        return icon && icon.id && icon.id === newIcon.id;
    });

    if (currentIcon) {
        var currentIconIndex = this.active.indexOf(currentIcon);
        this.active[currentIconIndex] = newIcon;

        this.trigger("add", { replaceIndex: currentIconIndex }, callback);
    } else {
        var insertIndex = this.addToStart ? 0 : this.active.filter(Boolean).length;
        this.active.splice(insertIndex, 0, newIcon);

        this.trigger("add", { insertIndex: insertIndex }, callback);

        var lastIndex = this.active.length - 1;
        if (this.active.length > 3 && !this.active[lastIndex]) {
            this.active.pop();

            this.trigger("remove", lastIndex, true);
        }
    }
};

ScrollableIconTable.prototype.scrollTo = function (icon, silent, callback) {
    this.trigger("scrollTo", this.active.indexOf(icon), silent, callback);
};

ScrollableIconTable.prototype.hasIcon = function (icon) {
    return this.active.includes(icon);
};

ScrollableIconTable.prototype.findIcon = function (icon) {
    return this.active.find(function (active) {
        return active === icon;
    });
};