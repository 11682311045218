/**
 * Created by olga on 14.11.2024
 */

var BaseLocation = function (type, locationId) {
    cleverapps.EventEmitter.call(this);

    this.type = type;
    this.locationId = locationId;

    var stored = cleverapps.meta.loadLocation(this.locationId);
    this.load(stored);
};

BaseLocation.prototype = Object.create(cleverapps.EventEmitter.prototype);
BaseLocation.prototype.constructor = Location;

BaseLocation.prototype.load = function () {
};

BaseLocation.prototype.getInfo = function () {
    return undefined;
};

BaseLocation.prototype.save = function () {
    var info = this.getInfo();
    if (info) {
        cleverapps.meta.saveLocation(this.locationId, info);
    }
};

BaseLocation.prototype.isMain = function () {
    return true;
};

BaseLocation.prototype.isExpedition = function () {
    return false;
};

BaseLocation.prototype.withEnergy = function () {
    return true;
};

BaseLocation.prototype.withStars = function () {
    return false;
};

BaseLocation.prototype.isCompleted = function () {
    return false;
};

BaseLocation.prototype.gamePlayed = function () {

};

BaseLocation.prototype.wantsToBeShown = function () {
    return false;
};
